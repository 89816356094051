@import url(https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;600;700&display=swap);
html,body{font-family:'Rubik', sans-serif}.wrap{padding:40px 30px}.page__header{display:flex;align-items:center;justify-content:space-between;flex-wrap:wrap;margin-bottom:30px}.page__header_left{display:flex;align-items:baseline}.page__header_title{font-size:45px;line-height:45px;font-weight:600;margin-right:30px}.page__header_subtitle{font-size:30px;font-weight:400;color:#969696}.ant-message{top:70px}.y-scroll::-webkit-scrollbar{width:16px}.y-scroll::-webkit-scrollbar-thumb{height:56px;border-radius:8px;border:4px solid transparent;background-clip:content-box;background-color:gray;width:16px}.y-scroll::-webkit-scrollbar-thumb:hover{background-color:#c6c6c6}

.loading-layout{height:100vh;display:flex;align-items:center;justify-content:center;flex-direction:column}.loading-layout__logo{width:20%;margin-bottom:18px;-webkit-animation-duration:3s;animation-duration:3s;-webkit-animation-name:breathe;animation-name:breathe;-webkit-animation-iteration-count:infinite;animation-iteration-count:infinite}@-webkit-keyframes breathe{from{opacity:30%}50%{opacity:70%}to{opacity:30%}}@keyframes breathe{from{opacity:30%}50%{opacity:70%}to{opacity:30%}}.loading-layout__logo-img{object-fit:contain}.loading-layout__hint{position:relative}.loading-layout__hint-spinner{position:absolute;left:calc(100% + 16px);bottom:0;font-size:20px}.loading-layout__hint-text{color:#666666}

.step-content{padding:24px 16px 0}

.copy-cad-obj-numbers-btn{color:#1890ff;font-size:12px}

.exec-result-prop{height:40px;display:flex;grid-gap:8px;gap:8px;align-items:center}.exec-result-prop__value{font-size:12px;display:flex;align-items:center;color:rgba(0,0,0,0.8);grid-gap:4px;gap:4px}

.exec-result-prop-container{display:grid;grid-auto-rows:1fr}

.history-table{padding:8px;background:#f7f7f7;border-radius:4px;display:flex;flex-direction:column;grid-gap:8px;gap:8px}.history-table__header{font-weight:500}

.error-message{padding:10px 0;color:#f87474}

.cad-object-num-selector__selected-items-addition{padding:10px 0;display:flex;align-items:center}.cad-object-num-selector__autocompleteAll-cbx{margin-left:auto}

.list-with-title{display:flex;flex-direction:column;grid-gap:8px;gap:8px;background-color:#f7f7f7;border-radius:4px;padding:8px}.list-with-title__title{font-weight:500}.list-with-title__list{display:flex;grid-gap:8px;gap:8px;flex-wrap:wrap}

.exec-item-view{display:flex;border:#e7e7e7 1px solid;color:rgba(0,0,0,0.85);background-color:#fff}.exec-item-view__body{padding:0 8px}.exec-item-view__time{border-left:#e7e7e7 1px solid;padding:0 8px}

.exec-in-process-container{display:grid;grid-template-areas:'queue exec' 'history history';grid-gap:16px;gap:16px;grid-template-columns:1fr auto}.exec-in-process-container__queue{grid-area:queue}.exec-in-process-container__exec{grid-area:exec}.exec-in-process-container__history{grid-area:history}

.queue-cad-object{border:#e7e7e7 1px solid;color:rgba(0,0,0,0.85);background-color:#fff;padding:0 8px}

.already-loaded-base__filters{display:flex;margin-bottom:16px}

.external-link-ceil{min-height:30px}.external-link-ceil--ellipsis a{display:block;overflow:hidden;white-space:nowrap;text-overflow:ellipsis}

.pd-record-popover{display:flex;flex-direction:column}.pd-record-popover>*:not(:last-child){margin-bottom:8px}.pd-record-popover__desc-item{display:flex;align-items:baseline}.pd-record-popover__prop-name{margin-right:8px;font-weight:500}

.create-pd-form{display:grid;grid-template-columns:2fr minmax(400px, 1fr);grid-template-areas:'form file';grid-gap:16px}.create-pd-form__title{grid-area:title}.create-pd-form__form{grid-area:form}.create-pd-form__file-zone{grid-area:file;padding-top:30px;display:flex;flex-direction:column}.create-pd-form__file{margin-bottom:16px}.create-pd-form__upload{flex-grow:1}

.file-card{border:1px solid #ebebeb;padding:8px;display:grid;grid-template-columns:auto 1fr auto auto;grid-template-rows:auto auto;grid-gap:8px;grid-template-areas:'title name name delete' 'size size uploadOn delete'}.file-card--with-no-title{grid-template-areas:'name name name delete' 'size size uploadOn delete'}.file-card--with-no-title .file-card__name{text-align:left}.file-card--with-no-title .file-card__name{text-align:left}.file-card__title{grid-area:title;font-weight:700}.file-card__name{text-align:right;grid-area:name;white-space:nowrap;overflow:hidden;text-overflow:ellipsis}.file-card__size{grid-area:size}.file-card__upload-on{grid-area:uploadOn;color:#969696}.file-card__delete{grid-area:delete;display:flex;align-items:center}

.sub-header{background-color:#f6fbff;padding:8px 30px;display:flex;grid-gap:10px;gap:10px;overflow-y:auto}.sub-header__left{display:flex}.sub-header__right{margin-left:auto;display:flex}

.dashboard-button{display:flex;align-items:center;background-color:transparent;border-radius:3px;border:0.5px solid #1890ff;transition:0.5s}.dashboard-button:hover{background-color:#1890ff}.dashboard-button:hover .dashboard-button__icon{color:white}.dashboard-button:hover .dashboard-button__text{color:white}.dashboard-button .ant-btn-loading-icon{font-size:12px;display:flex;align-items:center;color:#1890ff}.dashboard-button__icon{display:flex;align-items:center;color:#1890ff;margin-right:6px;font-size:12px}.dashboard-button__text{display:flex;align-items:center;font-size:10px;line-height:12px}

.app-state-widget{background-color:#494949;height:25px;display:flex;padding:2px 30px;justify-content:space-between;font-size:10px;color:#c8c8c8}.app-state-widget__left-side{display:flex}.app-state-widget__right-side{display:flex}

.widget-container{display:flex;align-items:center;grid-gap:4px;gap:4px;padding:2px 12px;border-right:1px solid #848484;border-left:1px solid #848484;transition:0.3s}.widget-container--clickable{cursor:pointer}.widget-container--clickable:hover{background-color:#626262}

.main-layout-footer{text-align:center;padding:15px 0;font-size:12px;color:#bdbdbd}

.main-layout-header{display:grid;grid-template-areas:'main devider nav additional';grid-template-columns:auto auto 1fr auto;background-color:#242424;padding:0 30px}.main-layout-header__main-zone{grid-area:main}.main-layout-header__devider-zone{grid-area:devider;display:flex;align-items:center}.main-layout-header__devider{margin:0 16px;width:1px;height:30px;background-color:#626262}.main-layout-header__nav-zone{grid-area:nav}.main-layout-header__additional-zone{grid-area:additional}

.header-icon-button{height:100%;display:flex;align-items:center}.header-icon-button.header-icon-button--large .header-icon-button__icon{font-size:32px}.header-icon-button:hover .header-icon-button__icon{color:#c8c8c8}.header-icon-button__icon{font-size:24px;color:#969696;transition:0.3s}

.popover-container{background:#fff;box-shadow:rgba(0,0,0,0.1) 0 20px 25px -5px,rgba(0,0,0,0.04) 0px 10px 10px -5px;border:1px solid #e7e7e7;padding:16px}.popover-container.popover-container--dark{background:#0e0e0e;border:1px solid black}

.company-icon-overlay{color:#e6e6e6;border-radius:3px;min-width:400px}.company-overlay{min-width:450px}.company-overlay__button-control{height:40px;width:100%}.company-overlay__button-wrapper{margin-top:15px;display:flex;align-items:center;width:100%}.company-overlay__button-wrapper .ant-space-item{width:100%}.company-overlay__button-change-company{height:40px;width:100%;color:#d32f2f}.company-overlay__button-change-company :hover{color:#d32f2f}.company-overlay__button-change-company ::after{color:#d32f2f}.company-overlay__title-field{font-size:12px;font-style:normal;font-weight:300;line-height:11px;color:#fff}.company-overlay__title-text{font-style:normal;font-weight:500;font-size:18px;line-height:16px;margin:5px 0 10px 0;color:#fff}.company-overlay__subtitle-field{font-size:12px;font-weight:500}.company-overlay__subtitle-text{font-size:16px;font-weight:300}

.header-logo-title{display:flex;align-items:center;grid-gap:8px;gap:8px;font-family:'Raleway', sans-serif}.header-logo-title--without-text .header-logo-title__logo-text{display:none}.header-logo-title__logo-icon{color:#1890ff;font-size:30px}.header-logo-title__logo-text{line-height:1;font-size:24px;font-weight:600;font-size:24px;color:#fff}.header-logo-title__logo-text-beta{position:relative;top:-14px;font-weight:300;color:#c8c8c8;font-size:10px}

.header-main-menu{display:flex;align-items:center;height:100%}.header-main-menu__company-zone{height:100%;margin-right:16px}.header-main-menu__logo-zone{display:flex;align-items:center;padding:8px 0}.header-main-menu__logo-select{min-width:215px;border-radius:5px}.header-main-menu__logo-select .ant-select-selector{background-color:#3d4b58 !important;border:1px solid #3d4b58 !important;color:white}.header-main-menu__logo-select .ant-select-arrow{color:white}.header-main-menu__additional-zone{margin-left:16px}

.header-nav-menu{background-color:transparent;border-bottom-color:transparent;border-bottom-width:0px;line-height:70px}.header-nav-menu .ant-menu-item::after,.header-nav-menu .ant-menu-submenu::after{left:0;right:0;bottom:1px}.header-nav-menu .ant-menu-item:hover::after,.header-nav-menu .ant-menu-submenu:hover::after{border-bottom:4px solid #1890ff !important}.header-nav-menu .ant-menu-item:hover .ant-menu-title-content,.header-nav-menu .ant-menu-item:hover .-title-content,.header-nav-menu .ant-menu-submenu:hover .ant-menu-title-content,.header-nav-menu .ant-menu-submenu:hover .-title-content{color:#fff}.header-nav-menu .ant-menu-item .ant-menu-title-content,.header-nav-menu .ant-menu-item .-title-content,.header-nav-menu .ant-menu-submenu .ant-menu-title-content,.header-nav-menu .ant-menu-submenu .-title-content{color:#c8c8c8}

.documentation-icon-button__no-documentation-text{color:#c8c8c8;font-size:14px}

.header__container{height:100%;display:flex;align-items:center}.header__avatar__container{height:100%;display:flex;align-items:center}.header__avatar{cursor:pointer}.header__avatar_welcome{padding:10px 15px;display:flex;flex-direction:column;grid-gap:8px;gap:8px}.header__avatar_welcomeText{font-size:12px;color:#c8c8c8}.header__avatar_welcomeName{font-size:16px}.header__avatar_welcomeRole{font-size:12px;font-style:italic}.header__avatar_dropdown{width:200px;padding:0;background:#fff;box-shadow:rgba(0,0,0,0.1) 0 20px 25px -5px,rgba(0,0,0,0.04) 0px 10px 10px -5px;border:1px solid #e2e2e2;z-index:10}.header__avatar_dropdown .ant-dropdown-menu{box-shadow:none}.header__avatar_dropdown .ant-menu-item,.header__avatar_dropdown .ant-menu-submenu-title{font-size:16px}.header__avatar_dropdown .ant-menu-item:last-child,.header__avatar_dropdown .ant-menu-submenu-title:last-child{color:red}.header__avatar_dropdown .ant-menu-item:hover,.header__avatar_dropdown .ant-menu-submenu-title:hover{background:#ebebeb}.header__avatar_dropdown .ant-menu-item:hover a,.header__avatar_dropdown .ant-menu-submenu-title:hover a{color:rgba(0,0,0,0.85)}.ant-dropdown-menu-item>a.header__avatar_logout{color:#d32f2f}

.notifications-icon-button{color:#c8c8c8;font-size:14px}

.header-additional{display:flex;align-items:center;height:100%;grid-gap:16px;gap:16px}.header-additional__devider{height:32px;width:2px;background-color:#626262}

.main-layout{min-height:100vh;max-width:100vw;width:100%;overflow-x:hidden;display:grid;grid-template-rows:auto 1fr auto auto;grid-template-areas:'header' 'content' 'footer' 'app_state'}.main-layout--single-page{max-height:100vh}.main-layout--single-page .main-layout__content{overflow-y:hidden}.main-layout__header{grid-area:header}.main-layout__content{grid-area:content;max-width:100vw}.main-layout__footer{grid-area:footer}.main-layout__app-state{grid-area:app_state}

.ant-mimic-input{width:100%}

.antd-label-mimic{padding-bottom:8px;display:flex;justify-content:space-between;align-items:center;grid-gap:12px;gap:12px}

.info-popup{border-radius:100%;background-color:#1890ff;border:1px solid #e7e7e7;font-size:11px;color:white;width:18px;height:18px;display:flex;align-items:center;justify-content:center;transition:0.3s}.info-popup:hover{background-color:#51a8fa}

.permission-settings-input{display:flex;flex-direction:column;grid-gap:16px;gap:16px}.permission-settings-input__info{display:flex;flex-direction:column;grid-gap:16px;gap:16px}.permission-settings-input__info-item{display:flex;flex-direction:column;grid-gap:8px;gap:8px}.permission-settings-input__info-item-title{font-weight:500;font-size:16px}

.boundary-header__layout{display:flex;align-items:center;justify-content:space-between;background-color:#F6FBFF;padding:30px 35px}.boundary-header__col{display:flex;align-items:center;justify-content:center;grid-gap:30px;gap:30px}.boundary-header__col h2{margin:0}.boundary-header__buttons{display:flex;align-items:center;justify-content:center;grid-gap:10px;gap:10px}

.boundary-info__layout{padding:10px}.boundary-cadaster{display:flex;flex-direction:column;grid-gap:30px;gap:30px}.boundary-cadaster__layout{padding:20px 10px;display:flex;flex-direction:column;grid-gap:8px;gap:8px;border:1px solid #E6E6E6;position:relative}.boundary-cadaster__title{position:absolute;top:-13px;left:30px;color:#1890FF;font-size:16px;font-weight:400;margin:0}.boundary-cadaster__title::after{content:'';position:absolute;top:50%;left:40%;-webkit-transform:translate(-50%, -50%);transform:translate(-50%, -50%);width:65px;height:20px;background:#fff;z-index:1}.boundary-cadaster__title p{position:relative;z-index:2;margin:0}.boundary-cadaster__title span{z-index:2;margin-right:5px;position:relative}.boundary-cadaster__title span::after{content:'';position:absolute;top:50%;left:50%;-webkit-transform:translate(-50%, -50%);transform:translate(-50%, -50%);width:23px;height:23px;border-radius:50%;border:1px solid #1890FF}

.boundary-docs__layout{padding:10px;display:flex;grid-gap:100px;gap:100px}.boundary-docs__file{margin-top:95px}

.boundary__form{padding:15px 30px;background-color:#F6FBFF;min-height:100vh}.boundary-cadaster__form-layout{padding:32px;background-color:#ffffff;border-radius:5px}

.guest-layout__content-center{grid-area:main;display:flex;flex-direction:column;justify-content:center}

.guest-layout-content-header{margin-bottom:24px}

.guest-layout__content-with-footer-container{height:100%;display:grid;grid-template-rows:1fr auto;grid-template-areas:'main' 'footer';grid-gap:16px;gap:16px}

.guest-layout__footer-container{grid-area:footer;display:flex;flex-wrap:wrap;grid-gap:16px;gap:16px}

.guest-layout-footer-link{color:#707070;text-decoration:underline;text-underline-offset:2px;font-size:12px}.guest-layout-footer-link:hover{color:#c4c4c4}

.guest-layout-description{color:#d8d8d8;color:#e6e6e6;font-size:12px}

.guest-layout-header{display:flex;align-items:center}.guest-layout-header__logo-img{width:30px;height:30px}.guest-layout-header__logo-text{color:white;margin-left:10px;display:flex;align-items:flex-start;line-height:1;font-size:24px;font-weight:600;font-family:'Raleway', sans-serif}.guest-layout-header__logo-beta{display:block;position:relative;font-size:10px;font-weight:300;top:2px;left:5px;color:#bdbdbd}

.guest-layout-title{font-size:24px;margin-bottom:16px;color:#fff;font-weight:500}

.guest-layout{display:grid;grid-template-columns:1fr 450px;grid-template-areas:'bg panel';min-height:100vh}.guest-layout .guest-layout--no-scroll{max-height:100vh}.guest-layout__image-container{grid-area:bg;overflow:hidden}.guest-layout__bg-container{grid-area:bg;display:flex;align-items:center;justify-content:center}.guest-layout__image{width:100%;height:100%;object-fit:cover;object-position:left center}.guest-layout__panel{max-height:100%;min-height:100%;grid-area:panel;padding:16px 32px;background-color:#111111;display:grid;grid-gap:16px;gap:16px;grid-template-rows:auto 1fr;grid-template-columns:1fr;grid-template-areas:'header' 'content'}.guest-layout__header{grid-area:header}.guest-layout__content{grid-area:content;overflow:hidden}

.companies-page-footer{display:flex;flex-wrap:wrap;grid-gap:16px;gap:16px}.companies-page-footer__link{color:#707070;text-decoration:underline;font-size:12px;white-space:nowrap}.companies-page-footer__link:hover{color:#c4c4c4}

html,body{font-family:'Rubik', sans-serif}.wrap{padding:40px 30px}.page__header{display:flex;align-items:center;justify-content:space-between;flex-wrap:wrap;margin-bottom:30px}.page__header_left{display:flex;align-items:baseline}.page__header_title{font-size:45px;line-height:45px;font-weight:600;margin-right:30px}.page__header_subtitle{font-size:30px;font-weight:400;color:#969696}.ant-message{top:70px}.y-scroll::-webkit-scrollbar{width:16px}.y-scroll::-webkit-scrollbar-thumb{height:56px;border-radius:8px;border:4px solid transparent;background-clip:content-box;background-color:gray;width:16px}.y-scroll::-webkit-scrollbar-thumb:hover{background-color:#c6c6c6}.companies-page-header__title{font-size:24px;font-weight:500;margin-bottom:16px;color:#fff}.companies-page-header__description{font-size:12px;color:#e6e6e6}

.app-button{font-size:14px;font-weight:400}

.company-list-item{border-radius:5px;padding:16px;display:grid;grid-template-areas:'title id' 'desc desc';grid-template-columns:1fr auto;grid-gap:16px;gap:16px;background-color:#313131;cursor:pointer;border:1px solid transparent}.company-list-item:hover{border-color:#1890ff}.company-list-item:hover .company-list-item__title{color:#1890ff}.company-list-item--overdue{background-color:#4f181c}.company-list-item__title{grid-area:title;font-size:18px;color:#fff;display:flex;align-items:center}.company-list-item__id{grid-area:id;font-size:12px;color:#969696;display:flex;align-items:center}.company-list-item__description{grid-area:desc;display:flex;flex-direction:column;grid-gap:16px;gap:16px}.company-list-item__description-section{display:flex;flex-direction:column;grid-gap:8px;gap:8px}.company-list-item__description-prop{display:flex;align-items:center;grid-gap:24px;gap:24px;font-size:12px}.company-list-item__description-prop-name{color:#e6e6e6;font-weight:500;width:100px}.company-list-item__description-prop-value{color:#c8c8c8}.company-list-item__description-message{font-size:10px;color:#c8c8c8}

.company-list-item-skeleton{padding:16px;border-radius:5px;background-color:#5a5a5a}

.company-list{display:flex;flex-direction:column;grid-gap:16px;gap:16px}

.companies-section{max-height:100%;display:flex;flex-direction:column;grid-gap:16px;gap:16px}.companies-section__list-wrapper{flex:1 1;overflow-y:auto}

.companies-page{min-height:100vh;max-height:100vh}.companies-page__content{height:100%;display:grid;grid-template-areas:'header' 'companies' 'footer';grid-template-rows:auto 1fr auto;grid-gap:16px;gap:16px}.companies-page__header{grid-area:header}.companies-page__companies{grid-area:companies;overflow:hidden}.companies-page__footer{grid-area:footer}

.contracts__title{font-size:18px;margin-bottom:15px;color:#c8c8c8}.contracts__favorite{margin-bottom:30px}

.contract{display:block;padding:30px;color:#111;box-shadow:rgba(0,0,0,0.1) 0 10px 15px -3px,rgba(0,0,0,0.05) 0px 4px 6px -2px;border:1px solid #e2e2e2;transition:all 0.3s ease;cursor:pointer}.contract__title{font-size:24px;line-height:1.5;font-weight:500}.contract__info_item{display:flex;align-items:center;justify-content:space-between;color:#c8c8c8}.contract__info_item:not(:last-child){margin-bottom:10px}.contract__info_item_name{margin-right:16px;white-space:nowrap}.contract__info_item_value{white-space:nowrap}.contract__top{display:flex;align-items:center;justify-content:space-between;margin-bottom:20px}.contract__favorite_star svg{width:24px;height:24px}.contract__favorite_star-outline{color:#cdcdcd;transition:all 0.3s ease}.contract__favorite_star-outline:hover{color:#e0e013}.contract__favorite_star-filled{color:#e0e013}.contract:hover{color:#1890ff;box-shadow:rgba(0,0,0,0.1) 0 20px 25px -5px,rgba(0,0,0,0.04) 0px 10px 10px -5px;-webkit-transform:translateY(-5px);transform:translateY(-5px)}

.contract-skeleton{width:400px;padding:30px;box-shadow:rgba(0,0,0,0.1) 0 10px 15px -3px,rgba(0,0,0,0.05) 0px 4px 6px -2px;border:1px solid #e2e2e2}

.contract-list{display:grid;grid-template-columns:repeat(auto-fill, 400px);grid-gap:16px}

.tab-content{padding:16px;border:1px solid #e6e6e6}

.shared-pd-tab-content{display:flex;flex-direction:column;grid-gap:8px;gap:8px}

.contract-fields__error-tab-icon{color:#ff4d4f}

.select-declarant__prop-name{font-weight:500;color:#707070}.select-declarant__prop-value{color:#707070}

.filter-dropdown-actions{display:flex;align-items:center;grid-gap:8px;gap:8px}.filter-dropdown-actions_set-filter-btn{flex:1 1}.filter-dropdown-actions_reset-filter{flex:1 1}

.filter-dropdown-content-base{padding:16px;display:flex;flex-direction:column;min-width:400px;max-width:800px}

.filter-dropdown-form-container{margin-bottom:8px}

.filter-type-select{display:flex;align-items:center;grid-gap:16px;gap:16px;padding-bottom:8px;border-bottom:1px solid #e7e7e7;margin-bottom:8px}.filter-type-select__items-list{display:flex;flex-wrap:wrap;align-items:center;grid-gap:8px;gap:8px}.filter-type-select__selected-item-label{flex:1 1}

.filter-type-select__option{border:2px solid #c8c8c8;color:#c8c8c8;position:relative;display:flex;align-items:center;justify-content:center;width:30px;height:30px;cursor:pointer}.filter-type-select__option::after{position:absolute;bottom:-7px;left:0;display:block;content:'';width:100%;height:3px;transition:0.3s}.filter-type-select__option--selected{border-color:#1890ff;color:#1890ff}.filter-type-select__option--hasFilter::after{background:#1890ff}

.section-header{display:flex;align-items:center;padding:8px 0;grid-gap:10px;gap:10px}.section-header__title{font-weight:700;font-size:18px;flex:0 1;white-space:nowrap}.section-header__divider{flex:1 1;height:1px;background-color:#e5e5e5}

.coords-table{width:100%}.coords-table__input{width:100%}.coords-table__td{padding:5px 10px}.coords-table__error-td{color:#ff4d4f;padding:5px 10px}

.coords-import-modal{display:flex;flex-direction:column;grid-gap:16px;gap:16px}.coords-import-modal__text-area{width:100%}.coords-import-modal__error-message{color:red}

.coords-import-modal{display:flex;flex-direction:column;grid-gap:16px;gap:16px}.coords-import-modal__text-area{width:100%}.coords-import-modal__error-message{color:red}

.polyline-point-item{padding:4px;display:flex;border-radius:4px;border:2px solid transparent;justify-content:space-between;grid-gap:8px;gap:8px;cursor:pointer}.polyline-point-item:hover{background-color:#f7f7f7}.polyline-point-item--selected{cursor:default;border:2px solid #1890ff}.polyline-point-item--selected .polyline-point-item__actions{visibility:visible;opacity:1}.polyline-point-item--selected .polyline-point-item__add-button{display:block}.polyline-point-item__cords-container{display:flex;grid-gap:16px;gap:16px;align-items:center;flex:1 1}.polyline-point-item__actions{visibility:hidden;opacity:0;display:flex;align-items:center;grid-gap:8px;gap:8px;transition:0.3s}.polyline-point-item__cord{display:flex;align-items:center;flex:1 1;grid-gap:8px;gap:8px}.polyline-point-item__cord-name{color:#c8c8c8;width:20px}.polyline-point-item__cord-value{flex:1 1;white-space:nowrap;overflow:hidden;text-overflow:ellipsis}.polyline-point-item__add-button{display:none;height:18px;line-height:1;padding:0}

.polyline-item{padding:8px;border:1px solid #e7e7e7}.polyline-item--selected{background-color:white}.polyline-item--selected .polyline-item__header{margin-bottom:8px}.polyline-item--selected .polyline-item__points-container{max-height:200px;overflow-y:auto}.polyline-item__header{display:flex;justify-content:space-between;cursor:pointer}.polyline-item__title{font-weight:500}.polyline-item__actions{display:flex;align-items:center}.polyline-item__points-container{flex-direction:column;display:flex;max-height:0px;transition:0.2s;overflow:hidden}.polyline-item__points-container::-webkit-scrollbar{width:0}

.polyline-list{padding:16px;border:1px solid #e7e7e7;border-radius:4px}.polyline-list__header{font-weight:500;margin-bottom:16px}.polyline-list__items-container{border-radius:4px;background-color:#f7f7f7}.polyline-list__polyline-item:first-child{border-top-left-radius:4px;border-top-right-radius:4px}.polyline-list__polyline-item:last-child{border-bottom-left-radius:4px;border-bottom-right-radius:4px}.polyline-list__add-polyline-zone{margin-top:16px;display:flex;grid-gap:8px;gap:8px}.polyline-list__add-polyline{flex:1 1}

.cords-manager-info{padding:0 8px;display:flex;flex-direction:column;grid-gap:16px;gap:16px}

.add-tp-form{display:flex;grid-gap:8px;gap:8px}.add-tp-form__select{flex:1 1}

.tp-list{padding:16px;border:1px solid #e7e7e7;border-radius:4px}.tp-list__header{font-weight:500;margin-bottom:16px}.tp-list__items-container{display:flex;flex-direction:column;margin-bottom:8px}

.tp-item{padding:8px;border:2px solid #e7e7e7;display:flex;justify-content:space-between;grid-gap:8px;gap:8px;background-color:#f7f7f7}.tp-item:first-child{border-top-left-radius:4px;border-top-right-radius:4px}.tp-item:last-child{border-bottom-left-radius:4px;border-bottom-right-radius:4px}.tp-item--selected{border-color:#1890ff}.tp-item__title{flex:1 1;cursor:pointer}.tp-item__title:hover{color:rgba(0,0,0,0.8)}

.cords-manager-canvas{background-color:#f6f6f6;border:1px solid #dedede;display:flex;align-items:center;justify-content:center;position:relative}.cords-manager-canvas__area{background-color:white}.cords-manager-canvas__actions-panel{position:absolute;top:8px;right:8px;padding:4px;display:flex;border:1px solid #dedede;background-color:rgba(255,255,255,0.247);border-radius:8px;grid-gap:8px;gap:8px}

.coords-manager{max-height:100%;padding:8px;display:grid;grid-template-areas:'canvas info';grid-template-columns:auto 1fr}.coords-manager__canvas{grid-area:canvas}.coords-manager__info{grid-area:info}

.cad-object-coord-page{display:flex;flex-direction:column}.cad-object-coord-page__back_to-contract{padding:8px;display:flex;align-items:center;grid-gap:8px;gap:8px}.cad-object-coord-page__header{padding:8px;display:flex;align-items:center;grid-gap:16px;gap:16px}.cad-object-coord-page__title{font-weight:500;font-size:16px}.cad-object-coord-page__have-changes{font-size:10px;color:#fff}.cad-object-coord-page__content{flex:1 1}

.cad-quarter-input{display:flex;grid-gap:18px;gap:18px;align-items:center}.cad-quarter-input__input{flex:1 1}.cad-quarter-input__quarter{color:#707070}

.xml-cell{min-height:30px;display:flex;align-items:center;grid-gap:16px;gap:16px}.xml-cell__actions{display:flex;align-items:center;justify-items:center;grid-gap:8px;gap:8px}.xml-cell__document{display:flex;align-items:center;grid-gap:6px;gap:6px}.xml-cell__document-name{white-space:nowrap;max-width:200px;overflow:hidden;text-overflow:ellipsis}.xml-cell__document-size{white-space:nowrap}.xml-cell__document-delete-button:hover{color:red;transition:0.5s}

.app-select-search{display:flex;align-items:center;background-color:transparent;border-radius:3px;border:0.5px solid #1890ff;transition:0.5s;font-size:10px;min-width:150px}.app-select-search .ant-select-selector{border:none !important;background:none !important}.app-select-search.app-select-search--dark{border:none}.app-select-search.app-select-search--dark{background-color:#3d4b58;border-color:#3d4b58}.app-select-search.app-select-search--dark .ant-select-arrow{color:white}.app-select-search.app-select-search--dark .ant-select-selection-search-input{color:white}.app-select-search.app-select-search--dark .ant-select-selection-item{color:white}.app-select-search .ant-select-arrow{align-self:center;font-size:8px;display:flex;align-items:center}.app-select-search__icon{display:flex;align-items:center;color:#1890ff;margin-right:6px;font-size:12px}.app-select-search__text{display:flex;align-items:center;font-size:10px;line-height:12px}.app-select-search__down{display:flex;align-items:center;font-size:12px;margin-left:8px;color:#1890ff}

.contract-statistic-overlay{background-color:#3d4b58;width:850px;padding:16px;border-radius:4px;box-shadow:rgba(100,100,111,0.2) 0px 7px 29px 0px;-webkit-transform:translateY(-10px);transform:translateY(-10px)}.contract-statistic-overlay__no-one-notify{color:#e0e0e0}.contract-statistic-overlay__header{display:flex;justify-content:space-between;align-items:center;grid-gap:16px;gap:16px;margin-bottom:16px}.contract-statistic-overlay__title{font-size:16px;font-weight:500;color:white}.contract-statistic-overlay__actions{display:flex;grid-gap:8px;gap:8px}.contract-statistic-overlay__stat-list{display:grid;grid-template-columns:1fr 1fr;grid-gap:8px;gap:8px}.contract-statistic-overlay__stat-item{padding:8px;background-color:#505e6b;border-radius:4px;display:grid;grid-template-columns:1fr 55px 50px 70px;grid-template-areas:'header count percent show' 'comment comment comment comment';grid-gap:0 8px;gap:0 8px;align-items:center}.contract-statistic-overlay__stat-item-header{grid-area:header;color:#e5e5e5;font-size:12px}.contract-statistic-overlay__stat-item-count{grid-area:count;color:#81e7bc;font-size:12px}.contract-statistic-overlay__stat-item-percent{grid-area:percent;color:#95a9bc;font-size:12px}.contract-statistic-overlay__stat-item-comment{padding-top:4px;grid-area:comment;color:#e7e7e7;font-size:12px}.contract-statistic-overlay__stat-item-show-btn{grid-area:show;font-size:12px}

.notify-overlay{background-color:#3D4B58;width:400px;padding:16px;border-radius:4px;box-shadow:rgba(100,100,111,0.2) 0px 7px 29px 0px;-webkit-transform:translateY(-10px);transform:translateY(-10px)}.notify-overlay__no-one-notify{color:#e0e0e0}.notify-overlay__header{font-size:16px;margin-bottom:16px;font-weight:500;color:white}.notify-overlay__action-list{display:flex;grid-gap:8px;gap:8px;flex-direction:column}.notify-overlay__action-item{padding:8px;background-color:#505E6B;border-radius:4px;display:grid;grid-template-columns:70px 1fr auto;grid-template-areas:'header header launch' 'count showLink launch';grid-gap:4px;gap:4px}.notify-overlay__action-item-header{grid-area:header;color:#fff}.notify-overlay__action-item-launch{grid-area:launch;display:flex;align-items:center;justify-content:center}.notify-overlay__action-item-count{grid-area:count;color:#81E7BC;font-size:18px}.notify-overlay__action-item-show-link{grid-area:showLink;display:flex;align-items:center}

.statistic-bar{padding:5px 30px;background-color:#35434a;display:flex;grid-gap:16px;gap:16px;align-items:center}.statistic-bar__btn{color:white;background:#3d4b58;border:1px solid #313e45}.statistic-bar__btn:hover,.statistic-bar__btn:active{background-color:#c8c8c8;color:#5a5a5a;border:1px solid #313e45 !important}.statistic-bar__btn:focus{background:#6f7177 !important;color:#373737;border:2px solid #1890ff !important}.statistic-bar__btn--notify{background:#1890ff}.statistic-bar__right{margin-left:auto;display:flex;grid-gap:8px;gap:8px;align-self:stretch;align-items:center}.statistic-bar__prop-list{display:flex;align-items:center;grid-gap:16px;gap:16px;flex-wrap:wrap}.statistic-bar__prop{display:flex;flex-direction:column;grid-gap:4px;gap:4px}.statistic-bar__prop--all-objects{display:flex;flex-direction:row;grid-gap:8px;gap:8px}.statistic-bar__prop--all-objects .statistic-bar__prop-name{width:-webkit-min-content;width:min-content}.statistic-bar__prop--all-objects .statistic-bar__prop-value{align-items:end}.statistic-bar__prop--all-objects .statistic-bar__prop-value-count{font-size:24px}.statistic-bar__prop-name{font-weight:200;color:#e5e5e5;font-size:12px;line-height:100%}.statistic-bar__prop-value{display:flex;grid-gap:8px;gap:8px;align-items:center}.statistic-bar__prop-value-count{font-size:12px;line-height:100%;font-weight:500;color:white}.statistic-bar__prop-value-percent{font-size:12px;line-height:100%;color:#95a9bc}.statistic-bar__prop-value-comment{font-size:10px;line-height:100%;color:#8c9eaf}.statistic-bar_detail-btn{height:100%;min-width:50px}.statistic-bar__notify-btn-container{height:100%;display:flex;align-items:center}

.auto-run-wizard-modal__selected-items-addition{padding:10px 0;display:flex;align-items:center}.auto-run-wizard-modal__autocompleteAll-cbx{margin-left:auto}

.batch-file-download-select-file-type-step{display:flex;flex-direction:column;align-items:center}.batch-file-download-select-file-type-step__file-type-field{margin-bottom:40px}.batch-file-download-select-file-type-step__file-type-field-select{width:100%}.batch-file-download-select-file-type-step__hint{margin:0 50px}

.batch-file-download-result-step{display:flex;align-items:center;grid-gap:40px;gap:40px}.batch-file-download-result-step__main{font-weight:600}.batch-file-download-result-step__desc{margin-bottom:16px}

.create-cad-object-modal__desc{margin-bottom:16px}.create-cad-object-modal__form .ant-input-number{width:100%}

.edit-pd-collection-in-cad-modal{display:flex;flex-direction:column;grid-gap:16px;gap:16px}.edit-pd-collection-in-cad-modal__actions{display:flex;align-items:center;grid-gap:8px;gap:8px}.edit-pd-collection-in-cad-modal__disabled-row{background-color:#f7f7f7}

.field-filling-wizard-modal__step-content{padding:24px 16px 16px}.field-filling-wizard-modal__selected-items-addition{padding:10px 0;display:flex;align-items:center}.field-filling-wizard-modal__autocompleteAll-cbx{margin-left:auto}.field-filling-wizard-modal__transfer-container{display:flex;flex-direction:column;align-items:center}.field-filling-wizard-modal__field-list{display:flex;flex-direction:column}.field-filling-wizard-modal__field-list-item{display:grid;grid-template-areas:'number fieldName .' 'number input clear';grid-template-columns:40px 1fr 150px;grid-gap:16px;gap:16px;padding-top:16px;border-bottom:1px solid #e2e2e2}.field-filling-wizard-modal__field-list-item-number{grid-area:number;font-size:20px;display:flex;align-items:center}.field-filling-wizard-modal__field-list-item-field-name{grid-area:fieldName}.field-filling-wizard-modal__field-list-item-field-name-prefix{color:#b2b2b2;margin-right:10px}.field-filling-wizard-modal__field-list-item-input{grid-area:input}.field-filling-wizard-modal__field-list-item-clear{grid-area:clear;display:flex;align-items:center}.field-filling-wizard-modal__final-form-warning{margin-top:16px}.field-filling-wizard-modal__final-form-warning-alert{text-transform:uppercase;color:red}

.field-filling-wizard-modal__step-content{padding:24px 16px 16px}.field-filling-wizard-modal__selected-items-addition{padding:10px 0;display:flex;align-items:center}.field-filling-wizard-modal__autocompleteAll-cbx{margin-left:auto}.field-filling-wizard-modal__transfer-container{display:flex;flex-direction:column;align-items:center}.field-filling-wizard-modal__field-list{display:flex;flex-direction:column}.field-filling-wizard-modal__field-list-item{display:grid;grid-template-areas:'number fieldName .' 'number input clear';grid-template-columns:40px 1fr 150px;grid-gap:16px;gap:16px;padding-top:16px;border-bottom:1px solid #e2e2e2}.field-filling-wizard-modal__field-list-item-number{grid-area:number;font-size:20px;display:flex;align-items:center}.field-filling-wizard-modal__field-list-item-field-name{grid-area:fieldName}.field-filling-wizard-modal__field-list-item-field-name-prefix{color:#b2b2b2;margin-right:10px}.field-filling-wizard-modal__field-list-item-input{grid-area:input}.field-filling-wizard-modal__field-list-item-clear{grid-area:clear;display:flex;align-items:center}.field-filling-wizard-modal__final-form-warning{margin-top:16px}.field-filling-wizard-modal__final-form-warning-alert{text-transform:uppercase;color:red}

.import-base{display:flex;flex-direction:column}.import-base__desc{padding:14px;border:0.5px solid #e6e6e6;display:grid;grid-template-columns:200px 1fr;grid-template-rows:auto 1fr;grid-template-areas:'example title' 'example list';grid-gap:8px;gap:8px;grid-column-gap:26px;-webkit-column-gap:26px;column-gap:26px;margin-bottom:18px}.import-base__desc-title{font-size:14px;font-weight:bold;grid-area:title}.import-base__desc-example{grid-area:example}.import-base__desc-example-img{width:100%;height:100%;object-fit:scale-down}.import-base__list{grid-area:list;list-style:decimal}.import-base__list-item{font-size:11px}

.auto-sign-result-step__actions-title{margin-bottom:12px;font-weight:600}

.button-with-menu{border-radius:3px;border:0.5px solid #1890ff;transition:0.5s}.button-with-menu .ant-btn{font-size:10px;border-radius:0}.button-with-menu:hover{background-color:#1890ff}.button-with-menu__overlay{border-radius:3px;border:0.5px solid #e7e7e7;background-color:white}.button-with-menu__overlay .ant-dropdown-menu{padding:0}.button-with-menu__overlay .ant-dropdown-menu .ant-dropdown-menu-item{font-size:12px;padding:5px 10px;line-height:1.5715;transition:0.5s}.button-with-menu__overlay .ant-dropdown-menu .ant-dropdown-menu-item:not(:last-child){border-bottom:0.5px solid #e7e7e7}

.app-dropdown-button{display:flex;align-items:center;padding:5px 10px;background-color:transparent;border-radius:3px;border:0.5px solid #1890ff;transition:0.5s}.app-dropdown-button:hover{background-color:#1890ff}.app-dropdown-button:hover .app-dropdown-button__icon{color:white}.app-dropdown-button:hover .app-dropdown-button__text{color:white}.app-dropdown-button:hover .app-dropdown-button__down{color:white}.app-dropdown-button.app-dropdown-button--as-text{border:none !important;background:none !important;padding:0 !important}.app-dropdown-button.app-dropdown-button--dark{background-color:#3d4b58}.app-dropdown-button.app-dropdown-button--dark .app-dropdown-button__icon{color:white}.app-dropdown-button.app-dropdown-button--dark .app-dropdown-button__text{color:white}.app-dropdown-button.app-dropdown-button--dark .app-dropdown-button__down{color:white}.app-dropdown-button__icon{display:flex;align-items:center;color:#1890ff;margin-right:6px}.app-dropdown-button__text{display:flex;align-items:center;font-size:10px;line-height:12px}.app-dropdown-button__down{display:flex;align-items:center;font-size:8px;margin-left:8px;color:#1890ff}.app-dropdown-button__overlay{border-radius:3px;border:0.5px solid #e7e7e7;background-color:white;box-shadow:0 3px 6px -4px rgba(0,0,0,0.12),0 6px 16px 0 rgba(0,0,0,0.08),0 9px 28px 8px rgba(0,0,0,0.05)}.app-dropdown-button__overlay-item{display:flex;align-items:center;padding:5px 10px;border-bottom:0.5px solid #e7e7e7;transition:0.5s;cursor:pointer}.app-dropdown-button__overlay-item:hover{background-color:#f5f5f5}.app-dropdown-button__overlay-item--danger{color:#ff4d4f}.app-dropdown-button__overlay-item--danger:hover{background-color:#ff4d4f;color:white}.app-dropdown-button__overlay-item-text{font-size:12px}.app-dropdown-button__overlay-item-icon{margin-right:12px}

.edit-file-collection-base__table{margin-bottom:16px}.edit-file-collection-base__new-file{margin-bottom:16px}

.vertical-middle-cell{vertical-align:middle}.column-filename{width:500px}

.file-card-couple{border:1px solid #e7e7e7;padding:8px;display:grid;grid-template-areas:'title title .' 'first second delete';grid-template-columns:1fr 1fr auto;grid-gap:8px;gap:8px}.file-card-couple__title{grid-area:title;font-weight:700}.file-card-couple__first{grid-area:first}.file-card-couple__second{grid-area:second}.file-card-couple__delete{grid-area:delete;height:100%}.file-card-couple-item{padding:8px;display:grid;grid-template-areas:'name name' 'size uploadAt';grid-template-columns:1fr auto;grid-gap:8px;gap:8px;border:1px dashed silver}.file-card-couple-item__name{grid-area:name;white-space:nowrap;overflow:hidden;text-overflow:ellipsis}.file-card-couple-item__size{grid-area:size;color:#969696}.file-card-couple-item__uploadAt{grid-area:uploadAt;color:#969696}

.load-file-modal__desc{display:flex;flex-direction:column;padding:8px}.load-file-modal__desc:not(:last-child){margin-bottom:16px}.load-file-modal__upload-list{margin-bottom:10px;display:flex;flex-direction:column}.load-file-modal__upload-list-change-on{align-self:center}.load-file-modal__accept_changes{align-self:flex-end;margin-top:16px}

.status-tag{width:100%;margin-right:0}.status-tag__select{width:100%}

.server-config-table__excluded-row td{background-color:#e4e3e3}.server-config-table__excluded-row .data-cell{pointer-events:none}.server-config-table__remarks-row td{background-color:#ffbbbb}.server-config-table__done-row td{background-color:#d2fcd4}.server-config-table__default-row .with-data{background-color:#d2fcd4}.server-config-table__default-row .soglasNo-filled{background-color:#ffbbbb}.server-config-table__address-cell .editable-base__input-container{min-width:200px}

.main-table-page-table__wrapper{padding:16px 32px}.main-table-page-table__loader-block{padding:20px;height:400px;display:flex;justify-content:center;align-items:center}.main-table-page-table__exclude{color:#bd8839}

.main-table-page .main-layout__content{display:flex;flex-direction:column}.main-table-page__content{display:flex;flex-direction:column;flex:1 1;justify-content:space-between;height:100%}.main-table-page__search-select{border-radius:5px !important;min-width:215px !important;margin-left:20px}.main-table-page__statistic-bar{position:-webkit-sticky;position:sticky;bottom:0;width:100%}

.management-employee__role-label-text{margin:0 10px 0 0}.management-employee__info-wrapper{padding:6px 9px;background-color:#e5e5e5}.management-employee__info-title{font-weight:500;font-size:14px;margin-bottom:3px;color:#000000}.management-employee__info-subtitle{font-weight:400;font-size:10px;margin-bottom:8px;color:#000000}

.string-input--block{width:100%}.string-input{width:100%}

.management-data__wrapper{margin:15px 30px 25px 30px;background-color:#fff;padding:20px}.management-data__main-title{font-size:20px;font-weight:700;margin-bottom:15px}.management-data__requisites{display:flex;flex-direction:column;width:100%}.management-data__requisites-address__row{height:30px;padding-bottom:8px}.subtitle2{font-size:14px;font-weight:400;line-height:12px;color:#b7b7b7;margin-top:40px}

.management-finance__wrapper{margin:15px 30px}.management-finance__left-card-recommend{margin-bottom:15px}.management-finance__left-card-chip{background-color:#d0ebff;border-radius:8px;padding:6px 10px;color:#707070;font-size:20px;display:inline-flex}.management-finance__left-card-chip-subtitle{margin-left:5px;font-size:10px}.management-finance__left-card-document-button{height:30px;width:100%}.management-finance__left-card-row-form{align-items:center}.management-finance__left-card-balance{display:flex}.management-finance__left-card-total{color:red;font-size:26px;font-weight:400}.management-finance__left-card-row-flex{display:flex;align-items:center;margin-bottom:15px}.management-finance__left-card-date{font-size:12px;font-weight:400}.management-finance__left-card-item{border-radius:5px;background-color:#ffffff;padding:20px}.management-finance__left-card-secondary{color:#707070;font-weight:300;font-size:12px}.management-finance__left-card-plan{background-color:#edfbff;border-radius:10px;padding:10px 15px;margin:15px 0}.management-finance__left-card-field{font-weight:500;font-size:10px}.management-finance__left-card-tariff{font-weight:400;font-size:12px}.management-finance__left-card-title{font-size:20px;font-weight:700;line-height:23px;letter-spacing:0em;margin-bottom:20px}.management-finance__left-card-header{font-size:12px;font-weight:400}.management-finance__left-card-data{font-weight:500;font-size:24px;display:inline-flex;align-items:flex-end}.management-finance__left-card-span{margin-left:4px;font-weight:400;font-size:10px;color:#707070}

.management-finance__right-card{background-color:#fff;padding:20px}

.management-header{padding:15px 30px}.management-header__subtitle{font-size:9px;font-weight:500}.management-header__subtitle-text{font-weight:300}.management-header__title{padding:10px 0 15px 0}.management-header__title-name{font-size:32px;font-weight:500}.management-header__title-price{font-size:26px;font-weight:400}.management-header__title-link{font-size:10px;font-weight:400;text-decoration:underline;color:#1890ff}.management-header__title-link :hover{cursor:pointer}.management-header__title-paid{font-size:10px;font-weight:400;color:#707070}.management-header__title-buttons{display:flex;justify-content:flex-end}.management-header__title-buttons>button{height:22px;border-radius:3px;border:0.5px solid #1890ff;font-size:10px}.management-header__title-buttons-generate{margin-right:10px;display:flex}.management-header__title-buttons-add{height:22px;border-radius:3px;border:0.5px solid #1890ff}

.management-menu{background-color:#e6f3fe;width:100%;padding:15px 30px}.management-menu__item{font-size:16px;text-decoration:underline;font-weight:400;color:#1890ff}.management-menu__item :hover{cursor:pointer}.management-menu__item.active{font-size:16px;font-weight:700;color:#000}


.management-statistic__button{font-size:24px;color:#1890ff;font-weight:600}.management-statistic__button.active{color:black;font-size:24px;font-weight:600}

.management-employee__wrapper{margin:15px 30px 25px 30px;background-color:#fff;padding:20px}.management-employee__main-title{font-size:20px;font-weight:700;margin:0 30px 15px 0}.management-employee__requisites{display:flex;flex-direction:column;width:100%}.management-employee__requisites-address__row{height:30px;padding-bottom:8px}.management-employee__button{border:0.5px solid #1890ff;border-radius:3px}.management-employee__icon{color:#1890ff}.subtitle2{font-size:14px;font-weight:400;line-height:12px;color:#b7b7b7;margin-top:40px}

.management-wrapper{background-color:#f6fbff;height:100%}.save-button{margin:9px 0 9px 33px}.custom-management__footer{padding:15px 0;font-size:12px;color:#bdbdbd}.management-item__wrapper{margin:15px 30px}.paper{padding:20px;border-radius:5px;background-color:#ffffff}

.login-form__email{height:60px;padding:20px}.login-form__password{height:60px;padding:20px}.login-form__buttons{display:flex;flex-direction:column;grid-gap:16px;gap:16px}.login-form__forgot-password{align-self:center;color:#969696;text-decoration:underline;text-underline-offset:2px}.login-form__forgot-password:hover{color:#c8c8c8}

.password-restore-confirmed-page-form__buttons{display:flex;flex-direction:column;grid-gap:16px;gap:16px}

.password-restore-page-form__buttons{display:flex;flex-direction:column;grid-gap:16px;gap:16px}.password-reset__email{height:60px;padding:20px}


.register-footer{display:flex;flex-wrap:wrap;grid-gap:16px;gap:16px}.register-footer__link{color:#707070;text-decoration:underline;font-size:12px;white-space:nowrap}.register-footer__link:hover{color:#c4c4c4}

.register-form-header__title{font-size:18px;margin-bottom:16px;color:white}.register-form-header__description{color:#d8d8d8}

.register-steps__steps{margin-bottom:16px}.register-steps__current-step-title{font-size:16px;color:white}

.forward-btn__content{display:flex;align-items:center;grid-gap:16px;gap:16px;justify-content:center}.forward-btn__forward-icon{font-size:12px}

.register-step-three__select{height:60px}.register-step-three__select :first-child{height:60px !important}.register-step-three__select :first-child :nth-child(2){padding:15px 0 0 15px}.register-step-three__select :nth-child(2){height:60px !important}.register-step-three__select :nth-child(2) :nth-child(2){padding:15px 0 0 15px}.register-step-three__email{height:60px;padding:20px}

.register-finish-step{display:flex;flex-direction:column;grid-gap:16px;gap:16px}.register-finish-step__title{font-size:34px;margin:20px 0 0 0;color:white}.register-finish-step__description{font-size:16px;color:#cecece}.register-finish-step__back_to-login{font-size:12px;margin:40px 0 0 0;background-color:#1890ff;height:60px}

.app-spinner{min-width:50px;min-height:50px;max-width:50px;max-height:50px;border-radius:50%;border:5px solid rgba(0,0,0,0.2);border-top-color:#1890ff;border-right-color:#1890ff;-webkit-animation:app-spinner-rotating 1.2s linear infinite;animation:app-spinner-rotating 1.2s linear infinite}@-webkit-keyframes app-spinner-rotating{0%{-webkit-transform:rotate(0deg);transform:rotate(0deg)}100%{-webkit-transform:rotate(360deg);transform:rotate(360deg)}}@keyframes app-spinner-rotating{0%{-webkit-transform:rotate(0deg);transform:rotate(0deg)}100%{-webkit-transform:rotate(360deg);transform:rotate(360deg)}}.app-spinner--dark{border-left-color:rgba(255,255,255,0.2);border-bottom-color:rgba(255,255,255,0.2)}.app-spinner--small{min-width:25px;min-height:25px;max-width:25px;max-height:25px}.app-spinner--large{min-width:75px;min-height:75px;max-width:75px;max-height:75px}.app-spinner--gigant{min-width:150px;min-height:150px;max-width:150px;max-height:150px}

.hint-block{border-radius:2px;padding:16px;display:grid;grid-template-areas:'icon title' 'desc desc';grid-template-columns:30px 1fr;grid-gap:24px;gap:24px;background-color:#e7e7e7}.hint-block--dark{background-color:#313131}.hint-block--dark .hint-block__title{color:#fff}.hint-block--dark .hint-block__description{color:#e6e6e6}.hint-block__icon{grid-area:icon}.hint-block__icon svg{width:100%;height:100%}.hint-block__icon--info{color:#1890ff}.hint-block__title{display:flex;align-items:center;grid-area:title;color:#fff;font-size:14px;font-weight:500}.hint-block__description{grid-area:desc;font-size:12px;font-weight:#e6e6e6}

.register-in-progress-step{height:100%;display:grid;grid-template-rows:auto 1fr auto;grid-template-areas:'title' 'content' 'hint';grid-gap:16px;gap:16px}.register-in-progress-step__title{grid-area:title;font-size:18px;color:white}.register-in-progress-step__content{grid-area:content;display:flex;flex-direction:column;align-items:center;justify-content:center}.register-in-progress-step__content-loader{margin-bottom:32px}.register-in-progress-step__content-title{font-size:12px;color:white;margin-bottom:16px}.register-in-progress-step__content-description{font-size:10px;color:#e6e6e6}.register-in-progress-step__hint{grid-area:hint}

.dadata-antd-input__container{position:relative}.dadata-antd-input__input{padding:4px 11px;border:1px solid #d9d9d9;border-radius:2px;transition:all 0.3s;color:rgba(0,0,0,0.85);font-size:14px;line-height:1.5715;width:100%}.dadata-antd-input__input:hover{border-color:#40a9ff;border-right-width:1px !important}.dadata-antd-input__input:focus{border-color:#40a9ff;box-shadow:0 0 0 2px rgba(24,144,255,0.2);border-right-width:1px !important;outline:0}.dadata-antd-input__input::-webkit-input-placeholder{color:#807676;opacity:0.6}.dadata-antd-input__input::-webkit-input-placeholder,.dadata-antd-input__input::placeholder{color:#807676;opacity:0.6}.dadata-antd-input__suggestions{position:absolute;display:flex;flex-direction:column;top:calc(100% + 4px);z-index:100;padding:4px 0;background-color:white;border-radius:2px;max-height:400px;overflow-y:auto;margin-bottom:0}.dadata-antd-input__suggestion{display:block;padding:5px 12px;color:rgba(0,0,0,0.85);font-weight:normal;font-size:14px;line-height:22px;cursor:pointer;transition:background 0.3s ease;background-color:transparent;border-radius:0;border:none}.dadata-antd-input__suggestion:hover{background-color:#f5f5f5}.dadata-antd-input__suggestion--current{color:rgba(0,0,0,0.85);font-weight:600;background-color:#e6f7ff}

.register-second-step__input{height:60px;padding:20px}.register-second-step__input-org :nth-child(1) :nth-child(1){height:60px;padding:20px}.register-second-step__input-address :nth-child(1) :nth-child(1){height:60px;padding:20px}

.register-step-one{height:60px;padding:20px}.register-step-one__password{height:60px;padding:20px}.register-step-one__phone input{height:60px;padding:20px}

.register-form__steps{margin-bottom:16px}.register-form__header{margin-bottom:24px}

.register-page__block-content{height:100%;display:grid;grid-template-rows:1fr auto;grid-template-areas:'form' 'footer'}.register-page__form{margin-bottom:24px;grid-area:form}.register-page__footer{grid-area:footer}

